import styled from '@emotion/styled';
import GetAppIcon from '@material-ui/icons/GetApp';
import { RouteComponentProps } from '@reach/router';
import { Breadcrumb, Button, Col, Divider, Row } from 'antd';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import DOMPurify from 'isomorphic-dompurify';
import { jsPDF } from 'jspdf';
import { head } from 'lodash';
import React, { useCallback } from 'react';

import { InternalContainer } from './basic-styles';
import { AddThisShare } from '../components/common/addthis-share';
import { useResponsive } from '../hooks/use-responsive';
import { useI18NContext } from '../i18n';
import PublicLayout from '../layout/public-layout';
import { programShortName } from '../siteContent';
import theme from '../theme';
import { getRealNodes } from '../utils/contentFilter';

type Member = {
  team_first_name: string;
  team_last_name: string;
  team_member_type: any;
  translation: {
    team_job_title: string;
    team_bio?: string;
    team_quote?: string;
  };
  company: any;
  team_photo: {
    data: {
      full_url: string;
    };
    localFile: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
};

const TeamContainer = styled(InternalContainer)`
  max-width: ${theme.screenLgMax};
  margin: 24px auto 0;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  & > span {
    color: ${theme.colorsBlack};
  }
  & > span:first-of-type a {
    text-decoration: underline;
  }

  & > span:last-of-type a {
    color: ${theme.colorsBlack};
  }
`;

const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
  && {
    color: ${theme.colorsBlack};
  }

  && + .ant-breadcrumb-separator {
    color: ${theme.colorsBlack};
    font-weight: bold;
  }
`;

const Header = styled.h2`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  padding: 15px 0;
  color: ${theme.colorsBlack};
`;

const SubHeader = styled.h6`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  color: ${theme.colorsBlack};
  margin-bottom: 40px;

  @media (max-width: ${theme.screenXsMax}) {
    padding-bottom: 15px;
  }
`;

const Wrapper = styled(Row)`
  margin: 0 -16px;
`;

const Text = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  padding-left: 32px;
  & p {
    margin-bottom: 35px;
  }
`;

const QuoteText = styled(Text)`
  padding-left: 0;
`;

const Image = styled(Img)`
  width: 100%;
  border-radius: 5px;
  @media (max-width: ${theme.screenSmMax}) {
    margin-bottom: 30px;
  }
`;

const QuoteWrapper = styled.div`
  margin-top: 30px;
`;

const Quote = styled.div`
  font-size: 100px;
  font-weight: bold;
  letter-spacing: 0;
  color: ${theme.colorsBlack};
  height: 60px;
`;

// The query used to provide the page data
export const query = graphql`
  query($id: Int!, $language: String) {
    teamMember: allDirectusTeamMember(filter: { directusId: { eq: $id } }) {
      nodes {
        team_first_name
        team_last_name
        translation(language: $language) {
          team_job_title
          team_bio
          team_quote
        }
        company
        team_photo {
          data {
            full_url
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        team_member_type {
          team_type_name
          team_type_slug
          translation(language: $language) {
            team_type_value
          }
          page {
            translation(language: $language) {
              team_type_value
            }
          }
        }
      }
    }
  }
`;

declare const window: any;

const TeamMember = ({ data: { teamMember }, location }: RouteComponentProps<any>) => {
  const member: Member | undefined = head(getRealNodes(teamMember));
  const { lg } = useResponsive();
  const { translateSlug } = useI18NContext();
  const isMobile = !lg;

  const generatePdfHandler = useCallback(async (member: any) => {
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontSize(17);

    doc.text(member.team_first_name + ' ' + member.team_last_name, 70, 33);

    doc.setFontSize(13);
    doc.text(member.translation?.team_job_title, 70, 62);
    member.company ? doc.text(member.company, 70, 67) : null;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = document.createElement('img');
    img.src = member.team_photo.localFile.childImageSharp.fluid.src;
    img.onload = function() {
      // set size proportional to image
      canvas.height = canvas.width * (img.height / img.width);
      // step 1 - resize to 50%
      const oc = document.createElement('canvas'),
        octx = oc.getContext('2d');
      oc.width = img.width * 0.5;
      oc.height = img.height * 0.5;
      octx?.drawImage(img, 0, 0, oc.width, oc.height);
      // step 2
      octx?.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);
      // step 3, resize to final size
      ctx?.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5, 0, 0, canvas.width, canvas.height);
      const encodedBase = canvas.toDataURL();
      doc.addImage(encodedBase, 'JPEG', 25, 25, 38, img.width > img.height ? 22 : 44);
    };

    doc.html(
      `<div style="font-size:4px;width:165px;margin:25px;margin-top:85px;text-align:justify ">
            <div>${member.translation?.team_bio || ''}</div>
            <div style="font-size:10px;margin-top:5px;">"</div>
            <div style="font-size:4px;margin:0px;padding:0px">${member.translation?.team_quote || ''}</div>
        </div>`,
      {
        callback: function(doc: any) {
          doc.save(`${member.team_first_name}-${member.team_last_name}.pdf`);
        },
      }
    );
  }, []);

  return (
    <PublicLayout seoTitle="Teams">
      <TeamContainer>
        <Row justify="space-between">
          <Col xs={24}>
            <StyledBreadcrumb separator=">">
              {member && (
                <StyledBreadcrumbItem
                  href={location?.state?.previousLink || translateSlug(member.team_member_type.team_type_slug)}
                >
                  {member.team_member_type?.translation?.team_type_value}
                </StyledBreadcrumbItem>
              )}
              {member && (
                <StyledBreadcrumbItem href="">
                  About {programShortName}: {member.team_member_type?.page?.translation?.team_type_value}
                </StyledBreadcrumbItem>
              )}
            </StyledBreadcrumb>
          </Col>
          {member && (
            <>
              <Col xs={24}>
                <Header>
                  {member.team_first_name} {member.team_last_name}
                </Header>
                <SubHeader>
                  <div>{member.translation?.team_job_title}</div>
                  <div>{member.company}</div>
                </SubHeader>
                <Wrapper justify={isMobile ? 'center' : 'space-between'}>
                  <Col lg={6} md={12} xs={20}>
                    {member?.team_photo?.localFile?.childImageSharp?.fluid && (
                      <Image
                        fluid={member?.team_photo?.localFile?.childImageSharp?.fluid}
                        alt="somthing"
                        className="photo"
                      />
                    )}
                  </Col>
                  <Col lg={18} md={24}>
                    {member.translation?.team_bio && (
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(member.translation?.team_bio),
                        }}
                      />
                    )}
                  </Col>
                </Wrapper>
                {member.translation?.team_quote && (
                  <QuoteWrapper>
                    <Quote>“</Quote>
                    <QuoteText
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(member.translation?.team_quote) }}
                    />
                  </QuoteWrapper>
                )}
              </Col>
            </>
          )}
        </Row>
        <Row justify="center" align="middle">
          <AddThisShare />
          <Col xs={24}>
            <Row justify="center" align="middle">
              <Col lg={8} md={8} xs={20}>
                <Divider />
              </Col>
            </Row>
          </Col>

          <Col xs={24}>
            <Row justify="center" align="middle">
              <Button onClick={() => generatePdfHandler(member)}>
                <GetAppIcon />
                Download Bio
              </Button>
            </Row>
          </Col>
        </Row>
      </TeamContainer>
    </PublicLayout>
  );
};

export default TeamMember;
